import React from "react";
import {showNotification, updateNotification} from "@mantine/notifications";
import {default as axios} from "axios";
import * as XLSX from "xlsx";
import {IconCheck, IconX} from "@tabler/icons-react";
import CustomButton from "../atoms/Button";

const StudentExcel = () => {
    const handleExport = async (param) => {
        try {
            showNotification({
                id: 'load-data',
                autoClose: false,
                disallowClose: true,
                loading: true,
                title: "Please Wait",
            });

            const response = await axios.get(`/student/userListForExport/${param}`);
            const data = response.data.subscription;

            const worksheet = XLSX.utils.json_to_sheet(data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Students");
            XLSX.writeFile(workbook, `student_list_${param}.xlsx`);

            updateNotification({
                id: 'load-data',
                color: 'teal',
                title: "Success",
                message: "The notification will close in 2 seconds, you can close this notification now",
                icon: <IconCheck size="1rem" />,
                autoClose: 1000,
            });
        } catch (error) {
            const message = error?.response?.data?.message || "An error occurred";
            showNotification({
                id: "error-notification",
                disallowClose: false,
                autoClose: 5000,
                title: message,
                message: "The notification will close in 2 seconds, you can close this notification now",
                color: "red",
                icon: <IconX size="1rem" />,
                loading: false,
                className: "my-notification-class",
                style: { backgroundColor: "white" },
            });
        }
    };

       return(
           <div style={{ display: 'flex', gap: '10px' }}>
               <CustomButton
                   text={"Inactive Subscribed Students"}
                   onClick={() => handleExport(1)}
               />

               <CustomButton
                   text={"Active Subscribed Students"}
                   onClick={() => handleExport(2)}
               />
           </div>
       )
}



export default StudentExcel